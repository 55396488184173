import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import router from './router/router'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;

/* FILTERS */

const formatCurrency = function (number) { return parseFloat(number).toLocaleString('en-ZA') }//{ return new Intl.NumberFormat().format(number) }

Vue.filter('formatCurrency', formatCurrency);

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
