import { render, staticRenderFns } from "./nav-menu-section-title.vue?vue&type=template&id=40439ca3&"
import script from "./nav-menu-section-title.vue?vue&type=script&lang=js&"
export * from "./nav-menu-section-title.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports