import Vue from 'vue';
import axios from 'axios'

export const authStore = Vue.observable({
    data: {
        user: {
            loggedIn: false,
            firstName: '',
            lastName: '',
            email: '',
            isAdmin: false
        },
        auth: {
            dialog: false,
            screen: 0
        },
    }
});

export const authStoreMutations = {
    checkUser: async function () {
        let result = await axios.get(`/api/Auth/AuthCheck`)
        if (result.status == 200 && result.data.success) {
            Object.assign(authStore.data.user, result.data);
            authStore.data.user.loggedIn = true;
            authStore.data.auth.dialog = false;
        }
        else {
            authStore.data.user.loggedIn = false;
            authStore.data.user.firstName = '';
            authStore.data.user.lastName = '';
            authStore.data.user.email = '';
            authStore.data.user.isAdmin = false;
            authStore.data.auth.dialog = false;
        }
    },
    signOut: async function () {
        let result = await axios.post(`/api/Auth/LogOut`)
        if (result.status == 200) {
            authStore.data.user.loggedIn = false;
            authStore.data.user.firstName = '';
            authStore.data.user.lastName = '';
            authStore.data.user.email = '';
            authStore.data.user.isAdmin = false;
            authStore.data.auth.dialog = false;
        }
    }
};