<template>
    <div>
        <div class="h5 font-weight-black">TERMS AND CONDITIONS FOR SA WILDLIFE STOCK ONLINE AUCTIONS</div>
        <div class="h6 font-weight-bold py-2">1. Applicable Law</div>
        <table>
            <tr>
                <td class="tcol-1">1.1</td>
                <td>The auction will be governed by the Laws of the Republic of South Africa.</td>
            </tr>
            <tr>
                <td class="tcol-1">1.2</td>
                <td>
                    The rules of this auction complies with section 45 of the Consumer
                    Protection Act 68 of 2008 (CPA) and Section 43(1) of the
                    Electronic Communications and Transactions Act 25 of 2002
                    (ECTA).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.3</td>
                <td>
                    Section 44(1) of the ECT affording purchasers a cooling off period
                    does not apply to online auctions.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.4</td>
                <td>
                    All persons, when registering as a bidder must, in doing so, comply
                    with Chapter 1 of the Financial Intelligence Centre Act (FICA).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.5</td>
                <td>
                    The Auctioneer is hereby appointed by the Purchaser and Seller to
                    act for and on behalf of the Purchaser and Seller as agent in
                    accordance with Section 54 of the Value-Added Tax Act 1991, in
                    respect of all tax invoices, credit and/or debit notes in respect of
                    all livestock, game and/or goods offered at the auction or any
                    costs of transport and insurances premiums paid for and on behalf
                    of the Purchaser and Seller.
                </td>
            </tr>

        </table>
        <div class="h6 font-weight-bold py-2">2. Bidder Registration</div>
        <table>
            <tr>
                <td class="tcol-1">2.1</td>
                <td>
                    Any person/entity who wishes to bid on the auction must register
                    as a bidder online and in doing so comply with Chapter 1 of FICA
                    by emailing required documentation to wernervos@igen.co.za.
                    For more information on the requirements please visit the FICA
                    website.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.2</td>
                <td>
                    The online auction service is only available to legal and natural
                    persons who can enter into legally binding contract under the laws
                    of South Africa. Without derogating from the generality thereof,
                    the auction services are not available to minors.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.3</td>
                <td>
                    Any person registering as a bidder online is deemed to be the
                    principle contracting party and no person will be allowed to
                    register as an agent of a third party unless with the consent of the
                    Auctioneer.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.4</td>
                <td>
                    By registering online as a bidder, the bidder consents to the
                    collection and use of personal information by the Auctioneer for
                    the purposes of creating the registration, and participation in the
                    online auction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.5</td>
                <td>
                    Where the bidder is a company, the person completing the online
                    registration must be duly authorised by resolution of the company
                    to register as a purchaser and shall upload a certified copy of the
                    resolution. (For purposes of this rule any reference to a company
                    will include any reference to juristic person including partnerships,
                    trusts or incorporated entities).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.6</td>
                <td>
                    Once registered, bidders must keep their account details
                    confidential and must not permit third parties to use or access
                    their account as purchasers will remain liable for all bids entered
                    via their account online.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">3. Pre-inspection of goods and/or livestock</div>
        <table>
            <tr>
                <td class="tcol-1">3.1</td>
                <td>
                    Once an animal has been entered into the auction, the seller may
                    not sell the animal. Death of the animal is the only reason for
                    withdrawal. Should the seller not adhere to the above, a fine of
                    R5000.00 will be payable to the Auctioneer within 7 days of the
                    date of the auction's closure.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.2</td>
                <td>
                    Bidders who wish to inspect the various lots which will be
                    presented at the auction, may inspect such lots online or at the
                    seller's premises at a time and date as arranged between the
                    prospective bidder and the seller.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.3</td>
                <td>
                    All livestock, game and goods are sold �voetstoots� and the
                    purchasers do not enjoy the protection of Section 55 and 56 of
                    the Consumer Protection Act.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.4</td>
                <td>
                    Any information provided regarding the quality, breeding, age,
                    date of insemination, condition, reproductive status or any
                    information regarding health, production or mass or any other
                    aspect of the livestock and/or game, is provided by the seller and
                    any misrepresentation by the seller is without the cooperation or
                    knowledge of the Auctioneer. Any right of recourse because of
                    such misrepresentation shall be against the seller.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">4. Bidding Procedure</div>
        <table>
            <tr>
                <td class="tcol-1">4.1</td>
                <td>
                    The auction shall take place at the date and time as advertised or
                    published and will not be postponed or delayed enabling any
                    member or group of the public to partake in the auction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.2</td>
                <td>
                    The auction could be subject to reserved prices settled by the
                    sellers. The Auctioneer does not have to point this out prior to the
                    sale of the said asset or lot.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.3</td>
                <td>
                    If the reserve price is not met, the seller can approve the sale to
                    the highest bidder.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.4</td>
                <td>
                    Should the Auctioneer become aware of any fault on advertising
                    material or any other publication or amendment to these Rules of
                    Auction, the Auctioneer will prior to the auction point out and
                    when necessary, amend such mistakes in the advertisement or
                    publication.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.5</td>
                <td>
                    Any bid made does not include VAT which, where applicable, will
                    be added to the bidding price for which a VAT invoice will be
                    issued.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.6</td>
                <td>
                    During a sale for which the bidder has registered, the bidder can
                    bid for a lot by clicking on the bid button.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.7</td>
                <td>
                    The online platform will automatically count down the time. If any
                    bid is received within two minutes of a lot's closing time, the lot
                    will automatically stay open for bidding for a further two minutes
                    and the automated bid increments will be adjusted. This can
                    continue until no bids are received within two minutes of closing
                    for each lot.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.8</td>
                <td>
                    The current bid will be displayed on the bidder's screen during the
                    sale.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.9</td>
                <td>
                    Until the fall of the hammer as contemplated in section 45(3) of
                    CPA, any bid may be declined by the Auctioneer if not compliant
                    with the CPA or the regulations issued in terms thereof.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.10</td>
                <td>
                    The bidder acknowledge that he/she are bidding at a live auction
                    and agrees that each bid entered is binding even if submitted in
                    error. The bidder accepts full responsibility for every bid
                    submitted through his/her online bidding account.
                </td>
            </tr>
            <tr> <td class="tcol-1">4.11</td> <td>Each lot is regarded as a separate sale transaction.</td> </tr>
            <tr>
                <td class="tcol-1">4.12</td>
                <td>
                    The sale by auction is complete at the fall of the hammer or any
                    other practice through which the Auctioneer recognises the
                    highest bidder as the purchaser.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.13</td>
                <td>
                    In the event of the highest bid being tied, the earliest bid received
                    or recognised wins. Any further disputes between bidders will
                    result in the lot being re-auctioned.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.14</td>
                <td>
                    The Auctioneer reserves the right to reject an online registration
                    and may withdraw permission for the use of the online auction
                    service for whatever reason before or during the sale.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">5. Payment</div>
        <table>
            <tr>
                <td class="tcol-1">5.1</td>
                <td>
                    All sales are for cash and purchase price is payable by electronic
                    funds transfer (EFT) within 24 hours to the Auctioneer on
                    acceptance of the bid. Any payment made in cash is further
                    subject to additional handling fees.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.2</td>
                <td>
                    <table>
                        <tr>
                            <td colspan="2">
                                Should the purchaser not pay the purchase price immediately and
                                has failed to make arrangements for payment with the
                                Auctioneer, then the Auctioneer is entitled to, after having
                                notified the PURCHASER in writing, per email or overnight courier,
                                to remedy the breach of contract, within 7 (seven) days from the
                                date of such request and may without prejudice to the
                                Auctioneer's other rights:
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.2.1</td>
                            <td>
                                cancel the sale and treat the assets or lots as unsold lots
                                which may again be presented on the auction for sale or be sold
                                out of hand by means of liaison services, depending on the seller's
                                mandate to the auctioneer; or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.2.2</td>
                            <td>
                                cancel the agreement and to receive and or to retain
                                as roukoop, or as a genuine estimate of damage or because of
                                any pending ruling to be made by a court of the real damage that
                                has been suffered, any money
                                Already paid by the PURCHASER; or alternatively
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.2.3</td>
                            <td>
                                cancel the agreement and claim a R15 000 fine and
                                all legal fees on a scale as between Attorney-and-own
                                client scale from the PURCHASER; or alternatively
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.2.4</td>
                            <td>
                                to insist on specific performance by the PURCHASER of
                                their obligations in terms of this agreement and to claim
                                compensation and all legal fees on a scale as between Attorneyand-
                                own client scale from them.
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.3</td>
                <td>
                    Both the Seller and the Purchaser consent to the jurisdiction of the
                    Magistrate's Court as contemplated in Section 45 of Act 32 of 1944 having
                    regard to any action which the Auctioneer may institute against the purchaser
                    or seller
                    irrespective of the cause of action. Notwithstanding the aforesaid
                    the Auctioneer will have the sole and absolute discretion to institute action in
                    any High Court with appropriate jurisdiction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.4</td>
                <td>
                    Payment of the purchase price minus SA Wildlife Stock's
                    commission (5% of purchase price plus VAT) will be paid to the
                    seller within 24 hours after receiving the duly signed invoice and
                    Proof of delivery.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.5</td>
                <td>
                    The auctioneer has the right to cancel any transaction or bid
                    before, during or after the auction without having to provide
                    reasons for this auction.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">6. Ownership and risk in the sold goods</div>
        <table>
            <tr>
                <td class="tcol-1">6.1</td>
                <td>
                    Ownership in the lot or assets will remain that of the seller until
                    the purchase price has been paid in full to the seller.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.2</td>
                <td>
                    Risk in the lot or assets passes to the purchaser (the highest
                    bidder) as soon as the animals or goods are loaded onto the
                    buyers transport.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.3</td>
                <td>
                    The sale of all lots and assets is subject to confirmation which
                    means that the owner/auctioneer must accept or reject the bid
                    after finalization of the auction. They have 2 (two) business days
                    to accept or reject the offer.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">7. Removal and Risk</div>
        <table>
            <tr>
                <td class="tcol-1">7.1</td>
                <td>
                    The risk of each lot will pass to the purchaser when animals is
                    loaded onto the purchasers transport by the seller or if specified otherwise.
                    The purchaser will at his own risk and cost remove the lots or assets from
                    the Seller's premises or auction terrain as arranged between the purchaser
                    and the seller.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">7.2</td>
                <td>
                    Removal of any assets or lots however will not be allowed by the
                    Auctioneer until payment of the purchase price by the purchaser or
                    acceptable arrangements for payment thereof have been made by the
                    purchaser with the
                    Auctioneer.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">7.3</td>
                <td>
                    SA Wildlife Stock will not accept liability for any loss or damage to
                    the goods or livestock as a result of the purchaser's failure to make
                    adequate and timeous arrangements for the removal of the livestock or
                    goods.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">7.4</td>
                <td>
                    Any party requiring permits, removal certificate, documentation of
                    identification or any other statutory prescribed document will solely be
                    responsible for obtaining same. Any instructions to an auctioneer with
                    regards to the
                    loading, transporting, choice of transport contractors, insurance or
                    choice of insurers will be executed at the sole risk of the person acquiring
                    such services and the Auctioneer will not be held responsible for any
                    losses whether direct
                    or indirect which may be suffered as a result of giving affect to the
                    instructions by the auctioneer.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">8. Limitation of Liability</div>
        <table>
            <tr>
                <td class="tcol-1">8.1</td>
                <td>
                    <table>
                        <tr>
                            <td colspan="2">
                                Notwithstanding the section 43 of ECTA, under no circumstances
                                will SA Wildlife Stock, its officers, directors, employees, or agents
                                be liable for any loss or damage caused by:
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">8.1.1</td>
                            <td>
                                The bidder's reliance on information obtained through
                                either the content or the services, or
                            </td>
                        </tr>
                        <tr><td class="tcol-1">8.1.2</td><td>By being exposed to information contained on the site, or</td></tr>
                        <tr>
                            <td class="tcol-1">8.1.3</td>
                            <td>
                                The defamatory, offensive, or illegal conduct of other
                                users or third parties, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">8.1.4</td>
                            <td>
                                The use or inability to use the website or the services or
                                content provided from or through the website, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">8.1.5</td>Any disruption in the internet connection resulting in the
                            bidder being disconnected from the online auction for
                            whatsoever reason, or
                            <td></td>
                        </tr>
                        <tr>
                            <td class="tcol-1">8.1.6</td>
                            <td>
                                The failure to record a bid because of being disconnected
                                or the online platform going offline during the auction process.
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        name: 'TermsAndConditions',
    }
</script>