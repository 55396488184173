<template>
    <div class="pa-2">
        <div style="height: 60vh;overflow-y:scroll">
            <terms-and-conditions></terms-and-conditions>
            <v-row justify="center">
                <v-col>
                    By having read these terms and conditions all bidders
                    consent to adhere to these rules
                </v-col>
            </v-row>
        </div>
        <v-row>
            <v-col>
            <v-btn block color="primary" v-on:click.stop="$emit('sign-in-clicked')"> Sign in </v-btn>
            </v-col>
            <v-col>
                <v-btn block color="primary" v-on:click.stop="$emit('sign-up-clicked')"> Sign up </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
    .tcol-1 {
        vertical-align: top;
        width: 10%;
    }
</style>

<script>
    import TermsAndConditions from '@/components/terms-and-conditions.vue';
    export default {
        name: 'TermsAndConditionsScreen',
        components: {
            TermsAndConditions
        },
        data: () => ({
          
        }),
        methods: {
            
        },
        computed: {
          
        }
    };
</script>