import Vue from 'vue';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export const hubStore = Vue.observable({
    auctionHub: null
});


export const hubStoreMutations = {
    setupHub: function () {
        hubStore.auctionHub = new HubConnectionBuilder()
            .withUrl('/auctionHub')
            .configureLogging(LogLevel.None)
            .build()
        hubStore.auctionHub.start()
    }
};