<template>
    <v-navigation-drawer :value="isDrawerOpen"
                         app
                         floating
                         width="260"
                         class="app-navigation-menu"
                         @input="val => $emit('update:is-drawer-open', val)">
        <!-- Navigation Header -->
        <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
            <router-link to="/"
                         class="d-flex align-center text-decoration-none">
                <v-img v-if="$vuetify.theme.dark"
                       :src="require('@/assets/images/logos/ws_logo.png')"
                       max-height="150px"
                       max-width="150px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo me-3"></v-img>
                <v-img v-else
                       :src="require('@/assets/images/logos/ws_logo.png')"
                       max-height="150px"
                       max-width="150px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo me-3"></v-img>

                <!--<v-slide-x-transition>
                    <h3 class="app-title text--primary text-center">
                        S.A STOCK WILDLIFE
                    </h3>
                </v-slide-x-transition>-->
            </router-link>
        </div>

        <!-- Navigation Items -->
        <v-list expand
                shaped
                class="vertical-nav-menu-items pr-5">
            <template v-for="(section, sectionIndex) in routes">
                <template v-if="!section.isAdminRequired || (section.isAdminRequired && user.isAdmin)">
                    <nav-menu-section-title :title="section.title" :key="`${sectionIndex}-menu-title`"></nav-menu-section-title>
                    <template v-for="(route, routeIndex) in section.routes">
                        <nav-menu-link v-if="!route.meta.hide && (route.meta.requiresAuth == (user.loggedIn) || route.meta.requiresAuth == null)"
                                       :title="route.meta.title"
                                       :to="route.path"
                                       :icon="route.meta.icon"
                                       :key="`${sectionIndex}-${routeIndex}-menu-link`">
                        </nav-menu-link>
                    </template>
                </template>
            </template>
            <nav-menu-link title="Subscribe"
                           v-on:list-item-clicked="showSubscribe = true"
                           :icon="`mdi-plus`"
                           :key="`subscribe-menu-link`">
            </nav-menu-link>
        </v-list>
        <v-dialog v-model="showSubscribe" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Subscribe <v-spacer></v-spacer>
                    <v-btn icon v-on:click="showSubscribe = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="firstName"
                                         label="First Name"
                                         placeholder="First Name"
                                         :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="lastName"
                                         label="lastName"
                                         placeholder="Last Name"
                                         :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field filled
                                          rounded
                                          v-model="email"
                                          label="Email"
                                          placeholder="john@example.com"
                                          :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12">
                            <v-textField filled
                                         rounded
                                         v-model="number"
                                         label="Mobile Number"
                                         placeholder="Number"
                                         :rules="[rules.required]" />
                        </v-col>

                        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                            <span class="me-2">
                                Click to receive notifications via e-mail
                            </span>
                        </v-card-text>
                        <v-col cols="12">
                            <v-btn block
                                   color="primary"
                                   :loading="loading"
                                   :disabled="!canSignUp"
                                   v-on:click="subscribe">
                                Subscribe
                            </v-btn>
                        </v-col>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <!--<a href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
           target="_blank"
           rel="nofollow">
            <v-img :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                   alt="upgrade-banner"
                   transition="scale-transition"
                   class="upgrade-banner mx-auto"
                   max-width="230"></v-img>
        </a>-->

    </v-navigation-drawer>
</template>

<script>
    import { routerOptions } from '../../router/routes.js'
    import NavMenuSectionTitle from './nav-menu-section-title.vue'
    import NavMenuLink from './nav-menu-link.vue'
    import { authStore } from '../../store/authStore'
    export default {
        name: 'SideMenu',
        components: {
            NavMenuSectionTitle,
            NavMenuLink
        },
        props: {
            isDrawerOpen: {
                type: Boolean,
                default: null,
            },
        },
        data: () => ({
            routes: routerOptions,
            user: authStore.data.user,
            showSubscribe: false,
            email: '',
            number: '',
            firstName: '',
            lastName: '',
            loading: false,
            rules: {
                required: value => !!value || 'Required.',
            }
        }),
        methods: {
            subscribe: async function () {
                this.loading = true;
                let model = {
                    email: this.email,
                    number: this.number,
                    firstName: this.firstName,
                    lastName: this.lastName
                };

                let result = await this.$axios.post(`/api/Subscribe/Subscribe`, model)
                alert(result.data.message)
                this.showSubscribe = false
                this.email = null
                this.number = null
                this.firstName = null
                this.lastName = null
                this.loading = false;
            },
        },
        computed: {
            canSignUp: function () {
                if (this.firstName.trim() == '') {
                    return false;
                }
                if (this.lastName.trim() == '') {
                    return false;
                }
                if (this.number.trim() == '') {
                    return false;
                }
                if (this.email.trim() == '') {
                    return false;
                }
                return true;

            }
        }
    };
</script>