<template>
    <div>
        <!-- login form -->
        <v-card-text>
            <v-form>
                <v-text-field v-model="firstName"
                              filled
                              rounded
                              label="First Name"
                              placeholder="John"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="lastName"
                              filled
                              rounded
                              label="Last Name"
                              placeholder="Doe"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="email"
                              filled
                              rounded
                              label="Email"
                              placeholder="john@example.com"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="cellphone"
                              filled
                              rounded
                              label="Cellphone"
                              placeholder="Mobile number"
                              :rules="[rules.required]"
                              class="mb-3"></v-text-field>

                <v-text-field v-model="password"
                              filled
                              rounded
                              :type="isPasswordVisible ? 'text' : 'password'"
                              label="Password"
                              placeholder="············"
                              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              :rules="[rules.required]"
                              @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
                <div>
                    <v-row align="center" justify="center">
                        <v-checkbox v-model="allowRegistration" class="mt-4">
                            <template v-slot:label>
                                <div>
                                    Accept&nbsp;
                                </div>
                            </template>
                        </v-checkbox>
                        <a v-on:click.stop="$emit('terms-and-conditions-screen-clicked')" class="v-label" style="margin-bottom: 2px">terms and conditions</a>
                    </v-row>

                    <v-btn block
                           color="primary"
                           :disabled="!canSignUp"
                           :loading="loading"
                           v-on:click="signUp">
                        Sign Up
                    </v-btn>
                </div>

            </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
                Already have an account?
            </span>
            <a text v-on:click="$emit('sign-in-clicked')">
                Sign in instead
            </a>
        </v-card-text>
    </div>
</template>

<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
    export default {
        name: 'SignInScreen',
        components: {
        },
        data: () => ({
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            cellphone: '',
            isPasswordVisible: false,
            loading: false,
            allowRegistration: false,
            rules: {
                required: value => !!value || 'Required.',
            }
        }),
        methods: {
            signUp: async function () {
                this.loading = true;

                let model = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    cellphone: this.cellphone,
                    password: this.password
                };

                if (this.firstName.trim() == '') {
                    return;
                }

                let result = await this.$axios.post(`/api/Auth/SignUp`, model)
                if (result.status == 200) {
                    this.events = result.data
                    this.$emit('signed-in');
                    alert('Sign up')
                }
                else {
                    alert(this.result.data.message)
                }

                this.loading = false;
            }
        },
        computed: {
            canSignUp: function () {
                if (this.allowRegistration) {
                    if (this.firstName.trim() == '') {
                        return false;
                    }
                    if (this.lastName.trim() == '') {
                        return false;
                    }
                    if (this.cellphone.trim() == '') {
                        return false;
                    }
                    if (this.email.trim() == '') {
                        return false;
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
        }
    };
</script>