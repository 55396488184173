function concatenateRoutes() {
    let routes = []
    for (let i = 0; i < routerOptions.length; i++) {
        routes.push(...routerOptions[i].routes)
    }
    //routes.push(...hiddenRoutes)
    return routes
}

export const routerOptions = [
    {
        title: 'Main Navigation',
        isAdminRequired: false,
        routes: [
            {
                path: '/', name: 'home', component: 'home/home-screen', meta: { title: 'Home', icon: 'mdi-home-outline' }
            },
            {
                path: '/auctions', name: 'auctions', component: 'auction/auctions-screen', meta: { title: 'Auctions', icon: 'mdi-gavel' }
            },
            {
                path: '/history', name: 'history', component: 'history/history-screen', meta: { title: 'Past Auctions', icon: 'mdi-clipboard-text-clock-outline' }
            },
            {
                path: '/bids', name: 'bids', component: 'bids/my-bids-screen', meta: { title: 'My Bids', icon: 'mdi-cash', requiresAuth: true }
            }
        ]
    },
    {
        title: 'Sub Navigation',
        isAdminRequired: false,
        routes: [
            {
                path: '/contact', name: 'contact', component: 'contact/contact-screen', meta: { title: 'Contact Us', icon: 'mdi-human-greeting-proximity' }
            },
            {
                path: '/terms', name: 'terms', component: 'terms/terms-screen', meta: { title: 'Terms and Conditions', icon: 'mdi-gavel' }
            },
            {
                path: '/how-to', name: 'howto', component: 'howtobid/how-to-bid', meta: { title: 'How to Bid', icon: 'mdi-help-circle' }
            }
        ]
    },
    {
        title: 'Admin',
        isAdminRequired: true,
        routes: [
            {
                path: '/admin', name: 'Admin', component: 'admin/admin-screen', meta: { title: 'Admin', icon: 'mdi-information-outline' }
            },
            {
                path: '/admin-auctions', name: 'Auctions', component: 'admin/admin-auctions-screen', meta: { title: 'Auctions', icon: 'mdi-gavel' }
            },
            {
                path: '/admin-user', name: 'Users', component: 'admin/admin-user-screen', meta: { title: 'Users', icon: 'mdi-account' }
            }
        ]
    }
]

export const routerRoutes = concatenateRoutes();