require('./overrides.scss')
//#CF9032, #CD7E2A, #6C3622
export default {
    theme: {
        themes: {
            light: {
                primary: '#6C3622',
                accent: '#CD7E2A',
                secondary: '#8A8D93',
                success: '#56CA00',
                info: '#16B1FF',
                warning: '#FFB400',
                error: '#FF4C51',
            },
            dark: {
                primary: '#CF9032',
                accent: '#CD7E2A',
                secondary: '#8A8D93',
                success: '#56CA00',
                info: '#16B1FF',
                warning: '#FFB400',
                error: '#FF4C51',
            },
        },
    },
}
