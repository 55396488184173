<template>
    <div>
        <!-- login form -->
        <v-card-text>
            <v-form>

                <v-text-field v-model="email"
                              filled
                              rounded
                              label="Email"
                              disabled
                              placeholder="john@example.com"
                              hide-details
                              class="mb-3"></v-text-field>

                <v-text-field v-model="password"
                              filled
                              rounded
                              :type="isPasswordVisible ? 'text' : 'password'"
                              label="Password"
                              placeholder="············"
                              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              hide-details
                              @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                <!--<v-checkbox hide-details
                            class="mt-1">
                    <template #label>
                        <div class="d-flex align-center flex-wrap">
                            <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                        </div>
                    </template>
                </v-checkbox>-->

                <v-btn block
                       color="primary"
                       class="mt-6"
                       :loading="loading"
                       v-on:click="signUp">
                    Reset password
                </v-btn>
            </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
                Don't want to change?
            </span>
            <a text v-on:click="$emit('sign-in-clicked')">
                Sign in instead
            </a>
        </v-card-text>

    </div>
</template>

<style lang="scss">
    @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
    export default {
        name: 'ResetPasswordScreen',
        components: {
        },
        data: () => ({
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            isPasswordVisible: false,
            loading: false
        }),
        methods: {
            signUp: async function () {
                this.loading = true;

                let model = {
                    email: this.email,
                    password: this.password,
                    token: this.token
                };

                let result = await this.$axios.post(`/api/Auth/ResetPassword`, model)
                if (result.status == 200 && result.data.success) {
                    location.href = '/'
                }
                else {
                    alert(result.data.message)
                }

                this.loading = false;
            }
        },
        mounted: function () {
            this.token = this.$route.query.token;
            this.email = this.$route.query.userName;
        }
    };
</script>