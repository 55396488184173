<template>
    <!-- Forgot password form -->
    <v-card-text>
        <v-form>
            <v-text-field v-model="email"
                          filled
                          rounded
                          hide-details
                          label="Email"
                          placeholder="john@example.com"
                          class="mb-3"></v-text-field>
            <v-btn block
                   color="primary"
                   class="mt-6"
                   :loading="loading"
                   v-on:click="requestReset">
                Reset Password
            </v-btn>
        </v-form>
        <v-card-text class="d-flex align-centre justify-centre flex-wrap mt-6">
            <v-row align="center" justify="center">
                <span class="me-2 align-centre">
                    Sign in instead?
                </span>
                <a text v-on:click="$emit('sign-in-clicked')">
                    Sign in
                </a>
            </v-row>
        </v-card-text>
    </v-card-text>
</template>

<script>
    export default {
        name: 'SignInScreen',
        components: {
        },
        data: () => ({
            email: '',
            loading: false
        }),
        methods: {
            requestReset: async function () {
                this.loading = true;

                let model = {
                    email: this.email
                };

                let result = await this.$axios.post(`/api/Auth/ForgotPassword`, model)
                if (result.status == 200) {
                    alert("Reset link sent, please check your emails.")
                    location.reload()
                }
                else {
                    alert(result.data.message)
                }

                this.loading = false;
            }
        },
    };
</script>
